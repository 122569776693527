import React, { useState, useEffect } from "react";
import logo from "_shared/images/delta.png";
import Navlinks from "../Navlinks/Navlinks";
import loadable from "@loadable/component";
import { Tooltip, Typography } from "@material-ui/core";
import SearchBox from "../SearchBox/SearchBox";
import { useFeature } from "_shared/feature.js";
import { pathOr } from "ramda";
import { Link } from "react-router-dom";
import "./mobileNav.scss";
import { useNotifications } from "../Notifications/Notifications.api";
import { getTotalPcfByRegion } from "../Notifications/Forms/FormAPI/Form.queries";

const getTooltipTypography = (title) => {
  return (
    <Typography className="font-default text-[12px] text-white">
      {title}
    </Typography>
  );
};

const Notifications = loadable(() =>
  import(/* webpackPrefetch: true */ "../Notifications/Notifications")
);
const FeatureToggle = loadable(() =>
  import(/* webpackPrefetch: true */ "../FeatureToggle/FeatureToggle")
);

const formatTotal = (num) => {
  return num > 100 ? "99+" : num;
};

const MobileNavBar = ({
  collapsed,
  setCollapsed,
  handleSearch,
  handleSearchSubmit,
  withBackground,
  user,
  searchPlaceHolder,
  noSearch = false,
  isMobile,
  history,
}) => {
  const [openPendingActions, setOpenPendingActions] = useState(false);
  const [showSub, setShowSub] = useState(false);
  const [isFeatureToggleOpen, setIsFeatureToggleOpen] = useState(false);
  const notifications = useNotifications({ refetchOnMount: false });
  const featureToggle = useFeature("feature toggle");
  ///////////////////////////////////// PCF //////////////////////////////////////////////////
  const [isCsTeamMember, setIsCsTeamMember] = useState(false);
  const isAMR = useFeature("Amr-cs-team");
  const isAME = useFeature("Ame-cs-team");
  const isAML = useFeature("Aml-cs-team");
  const isAuNz = useFeature("Am(au/nz)-cs-team");
  const [totalPCF, setTotalPCF] = useState(0);

  useEffect(() => {
    if (isAMR || isAME || isAML || isAuNz) {
      let pcfTotal = getTotalPcfByRegion(isAMR, isAME, isAML, isAuNz).then(
        (result) => {
          console.log("result in SearchBar useEffect: ", result);
          setTotalPCF(result);
          return result;
        }
      );
      setIsCsTeamMember(true);
      console.log("pcf total in SearchBar: ", pcfTotal);
    }
  }, [isAMR, isAME, isAML, isAuNz]);

  ///////////////////////////////////// PCF //////////////////////////////////////////////////

  const handleNotificationsClick = () => {
    setOpenPendingActions(!openPendingActions);
    history.push("/notifications");
  };

  useEffect(() => {
    if (
      pathOr(false, ["location", "pathname", "indexOf"], history) &&
      history.location.pathname.indexOf("/notifications") !== -1
    ) {
      setOpenPendingActions(true);
    }
  }, [history]);

  return (
    <div className={`${withBackground && collapsed ? "bg-white" : ""}`}>
      <div
        className={`${
          withBackground && collapsed
            ? "h-[75px] w-screen border-none bg-white opacity-50"
            : "h-[70px] w-screen bg-black opacity-60"
        }`}
      />
      {!collapsed && (
        <Link to="/" onClick={() => setCollapsed(true)}>
          <img
            data-cy="applied-logo"
            className="absolute left-5 top-[15px] z-[2] h-9 w-auto animate-fade-in"
            alt="screen background"
            src={logo.src}
            srcSet={logo.srcSet}
          />
        </Link>
      )}
      {!collapsed && (
        <i
          data-cy="mobile-nav-burger iconContainer"
          className="fal fa-times fa-2x absolute right-5 top-4 animate-fade-in cursor-pointer text-white"
          role="button"
          onKeyPress={() => {
            setCollapsed(true);
          }}
          onClick={() => {
            setCollapsed(true);
          }}
          tabIndex={0}
        />
      )}
      {!collapsed && (
        <div data-cy="sidenav_lg" className="absolute left-[90px] top-0 z-[4]">
          <ul
            className={`absolute left-[-90px] top-[80px] w-screen animate-fade-in list-none p-0 text-[1.6rem] font-normal uppercase [&_a]:block [&_a]:py-3 [&_a]:pl-6 [&_a]:pr-0 [&_a]:text-white [&_li]:m-0 [&_li]:w-full ${
              collapsed ? "hidden" : ""
            }`}
            data-cy="sidenav_lg__items fadeInText"
          >
            <Navlinks setCollapsed={setCollapsed} user={user} />
          </ul>
        </div>
      )}
      <div
        data-cy="sidenav-opacity"
        className={`z-[3]  h-[calc(100vh-70px)] w-screen bg-black opacity-60 ${
          collapsed ? "hidden" : ""
        }`}
      />
      {collapsed && (
        <i
          data-cy="mobile-nav-burger"
          className={`fal fa-bars fa-align-left fa-2x absolute left-[25px] top-[21px] animate-fade-in cursor-pointer  ${
            withBackground ? "text-[#a3a9ac]" : "text-white"
          }`}
          role="button"
          onKeyPress={() => {
            setCollapsed(false);
          }}
          onClick={() => {
            setCollapsed(false);
          }}
          tabIndex={0}
        />
      )}
      {collapsed && !withBackground && (
        <i
          className={`fal fa-2x fa-clipboard-list-check absolute right-[25px] top-[21px] z-[1] animate-fade-in cursor-pointer ${
            withBackground ? "text-gray-shades-50" : "text-white"
          }`}
          onClick={handleNotificationsClick}
          data-cy="pending-actions-icon"
        >
          {(notifications.data.totalPending > 0 || totalPCF > 0) &&
            (!notifications.isLoading || totalPCF > 0) && (
              <span
                data-cy="badge"
                className="absolute left-[10px] top-[-8px] rounded-lg bg-alert px-[5px] py-[2px] font-default text-[12px] font-semibold text-white"
              >
                {formatTotal(totalPCF + notifications.data?.totalPending)}
              </span>
            )}
        </i>
      )}
      <div
        data-cy="iconContainer"
        className={`${
          collapsed ? "flex" : "hidden"
        } absolute right-[25px] top-5 items-center justify-center text-white `}
        onClick={() => {
          setShowSub(!showSub);
        }}
      >
        {withBackground && !noSearch && (
          <div
            className="absolute right-0 top-[-3px] md:right-[20px] md:top-[-9px]"
            data-cy="searchBoxPositioning"
          >
            <SearchBox
              handleSearchSubmit={handleSearchSubmit}
              handleSearch={handleSearch}
              withBackground={withBackground}
              searchPlaceHolder={searchPlaceHolder}
              width={205}
            />
          </div>
        )}
        <Notifications
          openPendingActions={openPendingActions}
          setOpenPendingActions={setOpenPendingActions}
          isMobile={isMobile}
          history={history}
          user={user}
          TeamId={user.TeamId}
          totalPCF={totalPCF}
          isCsTeamMember={isCsTeamMember}
        />
        {featureToggle && !handleSearch && (
          <div className="mr-[53px] mt-[2px] h-6">
            <Tooltip arrow title={getTooltipTypography("Feature Toggle")}>
              <i
                className="fas fa-book"
                onClick={() => setIsFeatureToggleOpen(true)}
              />
            </Tooltip>
            <FeatureToggle
              isFeatureToggleOpen={isFeatureToggleOpen}
              setIsFeatureToggleOpen={setIsFeatureToggleOpen}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileNavBar;
