import React, { useState, useEffect } from "react";
import AppContainer from "../AppContainer/AppContainer";
import propTypes from "prop-types";
import Loading from "../Loading/Loading";
import {
  login as authenticate,
  jwtLogin,
  acquireLegacyJwt,
  updateMsalJWT,
  checkedForLegacy,
} from "_shared/auth";
import {
  getTokenRedirect as acquireMsalToken,
  initializeMsal,
  signOut,
} from "_shared/authRedirect";
import { getRedirectRoute } from "../AuthorizedRoute/AuthorizedRoute";
import { useForm } from "react-hook-form";
import { isEmpty } from "ramda";
import { Redirect } from "react-router-dom";
import {
  restoreDefault,
  HOSTNAME_WHITE_LIST,
  hostname as CurrentDomain,
} from "../../utils/constants";
import "./LogInBox.scss";
import { useTranslation } from "react-i18next";
import CircleX from "_shared/images/icons/circle-x.svg";

const SIGNOUTTIMER = 5 * 1000;
const AZURERETRYENVCOUNT = "applied-azure-retry-count";

const azureAdFailed = () => {
  console.log("azureFailed");
  const retryCount = global.sessionStorage.getItem(AZURERETRYENVCOUNT);
  const count = retryCount !== null ? parseInt(retryCount, 10) : 0;
  global.sessionStorage.setItem(AZURERETRYENVCOUNT, count + 1);
};

const resetAzure = () => {
  console.log(
    "azure ad successful, resetting count from",
    global.sessionStorage.getItem(AZURERETRYENVCOUNT),
    "to 0"
  );
  global.sessionStorage.setItem(AZURERETRYENVCOUNT, 0);
};

const LogInBox = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [azureFailed, setAzureFailed] = useState(false);
  const [awatingSignout, setAwatingSignout] = useState(false);
  const [counter, setCounter] = useState(SIGNOUTTIMER);
  const { t } = useTranslation();

  const onSubmit = async (auth) => {
    try {
      setError("");
      setLoading(true);
      const data = await jwtLogin(auth)();

      if (data) {
        setIsLoggedIn(true);
        setLoading(false);
      } else {
        setError("Wrong username/password or server issues");
        setIsLoggedIn(false);
        setLoading(false);
      }
    } catch (e) {
      setError("Wrong username/password or server issues");
      setIsLoggedIn(false);
    }
  };

  const login = async () => {
    setLoading(true);
    let initResponse = await initializeMsal();
    const loginProcess = async () => {
      try {
        setError("");
        const msalRespObj = await acquireMsalToken();
        updateMsalJWT(msalRespObj);
        const data = await acquireLegacyJwt(msalRespObj);
        if (data.CostCenter === "") {
          setAwatingSignout(true);
          setTimeout(() => {
            signOut(msalRespObj.account.username);
          }, SIGNOUTTIMER);
        } else if (!isEmpty(data)) {
          if (data.username !== "zzKiosk") {
            setIsLoggedIn(true);
            resetAzure();
          }
          if (data.username === "zzKiosk") {
            setIsLoggedIn(false);
            setError("");
          }
        } else {
          setIsLoggedIn(false);
          setAzureFailed(true);
        }
      } catch (e) {
        console.warn({ e });
        if (
          (parseInt(global.sessionStorage.getItem(AZURERETRYENVCOUNT), 10) <=
            2 ||
            global.sessionStorage.getItem(AZURERETRYENVCOUNT) === null) &&
          checkedForLegacy === true
        ) {
          setError(`${e}`);
        }
        setIsLoggedIn(false);
        azureAdFailed();
      } finally {
        setLoading(false);
      }
    };

    if (
      parseInt(global.sessionStorage.getItem(AZURERETRYENVCOUNT), 10) <= 2 ||
      global.sessionStorage.getItem(AZURERETRYENVCOUNT) === null
    ) {
      loginProcess();
    } else {
      await legacyLogin();
    }
  };

  const legacyLogin = async () => {
    try {
      setLoading(true);
      setAzureFailed(true);
      const data = await authenticate();
      resetAzure();
      console.log({ data });
      if (!isEmpty(data) && data.username !== "zzKiosk") {
        setIsLoggedIn(true);
      } else if (!isEmpty(data) && data.username === "zzKiosk") {
        setIsLoggedIn(false);
        setError("");
      } else {
        setIsLoggedIn(false);
        setError(t("Wrong username/password or server issues"));
      }
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.jwt) {
      legacyLogin();
    } else {
      login();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter - 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, [awatingSignout, counter]);

  const { handleSubmit, register, errors } = useForm({
    nativeValidation: true,
  });

  const redirectRoute = getRedirectRoute();

  const redirectTo = redirectRoute ? redirectRoute : "/";


  return isLoggedIn ? (
    <>
    <Redirect to={redirectTo} />
    </>
  ) : (
    <AppContainer
      withBackground={false}
      render={({ width, height }) => {
        return (
          <div
            // styleName="loginContainer"
            className="flex flex-col items-center justify-center"
            style={{ width, height }}
          >
            {!awatingSignout ? (
              azureFailed ? (
                <div
                  // styleName="greetings"
                  className="[&_p]:mx-0 [&_p]:mb-10 [&_p]:mt-0 [&_p]:text-center [&_p]:text-[30px] [&_p]:text-white"
                >
                  <p>{t("Please sign in!")}</p>
                </div>
              ) : (
                !error && (
                  <div className="h-[164px] w-[281px] rounded-lg bg-white text-center">
                    <div className="pb-5 pt-8">
                      <Loading />
                    </div>
                    <p
                      className="m-0 text-center text-[22px] font-semibold text-[#373D43]"
                      style={{ textShadow: "none" }}
                    >
                      {t("Logging you in...")}
                    </p>
                  </div>
                )
              )
            ) : (
              <div
                //  styleName="greetings"
                className="[&_p]:mx-0 [&_p]:mb-10 [&_p]:mt-0 [&_p]:text-center [&_p]:text-[30px] [&_p]:text-white"
              >
                <p>
                  {`Logging you out in ${
                    counter / 1000
                  } due to the use of an admin(el) / or invalid account, please use standard accounts`}
                </p>
              </div>
            )}
            <div
              // styleName="search"
              className="flex flex-col items-center justify-center [&_*]:m-[3%]"
            >
              {azureFailed && (
                <>
                  <form
                    // styleName="loginContainer"
                    className="flex flex-col items-center justify-center"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <input
                      type="text"
                      name="username"
                      data-cy="username"
                      className="form-control box-border h-11 w-[250px] rounded-[20px] border-none p-[17px] [&:-ms-clear]:hidden [&:-ms-clear]:h-0 [&:-ms-clear]:w-0"
                      // styleName="search-bar"
                      required
                      placeholder={t("Username")}
                      ref={register({ required: true, maxLength: 80 })}
                    />
                    <input
                      ref={register({ required: true, maxLength: 80 })}
                      type="password"
                      data-cy="password"
                      name="password"
                      className="form-control box-border h-11 w-[250px] rounded-[20px] border-none p-[17px] [&:-ms-clear]:hidden [&:-ms-clear]:h-0 [&:-ms-clear]:w-0"
                      // styleName="search-bar"
                      placeholder={t("Password")}
                    />
                    <input
                      type="submit"
                      data-cy="submit"
                      // styleName="loginButton"
                      className="h-11 w-[200px] rounded-[20px] border-none bg-secondary text-white"
                      value="Login!"
                    />
                  </form>
                </>
              )}
            </div>
            {error && (
              <>
                <div
                  // styleName="authContainer"
                  className="flex h-[238px] w-[527px] items-center justify-center rounded-lg bg-white [&_p]:[text-shadow:none]"
                >
                  <div className="flex h-[178px] w-[487px] flex-col items-center justify-center">
                    <img className="h-[50px] w-[50px] pb-4" src={CircleX} />
                    <p className="m-0 text-[22px] font-semibold text-[#373D43]">
                      Authentication Failed
                    </p>
                    <div
                      className="w-[415px] pt-4 text-justify"
                      style={{ textAlignLast: "center" }}
                    >
                      <p className="m-0 text-[14px] leading-[22px] text-[#373D43]">
                        Please check your network connection, refresh and try
                        again.{" "}
                      </p>
                      <p className="m-0 text-[14px] leading-[22px] text-[#373D43]">
                        If the issue persists, try again later or contact our
                        ServiceDesk team for assistance.
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        );
      }}
    />
  );
};

LogInBox.propTypes = {
  location: propTypes.shape({ state: propTypes.object.isRequired }),
};

export default LogInBox;
