import React from "react";

const MobileHeader = ({ title, withBackground, history, onTitleClick }) => {
  const generateURLtitle = () => {
    switch (title) {
      case "Applied Company Store":
        return `/applied-shop/home`;
      default:
        return ``;
    }
  };

  const noTitleList = ["Campus Maps"];
  const dontShow = noTitleList.includes(title);

  const returnElement = () => {
    return dontShow ? (
      <></>
    ) : (
      <p
        className={` mb-5 mr-0 mt-0 inline-block text-[25px] font-normal leading-[20px] md:block ${
          withBackground ? "text-secondary" : "text-white"
        }`}
        onClick={() => {
          onTitleClick ? history.push(generateURLtitle()) : () => {};
        }}
      >
        {title}
      </p>
    );
  };

  return returnElement();
};

export default MobileHeader;
