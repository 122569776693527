import React from "react";
import Star from "../Star/Star";
import propTypes from "prop-types";
import { NavLink, useRouteMatch } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { ToTitleCase } from "_shared/helpers";

const SubNav = ({
  resetToTop,
  height,
  width,
  page,
  currentTab,
  setCurrentTab,
  TeamId,
  isMobile,
  isMobileCitySelector = false,
  history,
  closeModal,
  resources,
}) => {
  const { url: currentPath } = useRouteMatch();

  const handleTabClick = (url) => {
    if (closeModal) {
      closeModal();
    }
    history.push(url);
  };

  const getNavLinkClass = (url) => {
    if (resources) {
      return currentPath.indexOf(url) !== -1;
    }

    return currentPath === url;
  };

  const getTitleName = (Tab) => {
    const pageLocation = currentPath.split("/");
    if (pageLocation[1] === "discounts") {
      return Tab.display_text;
    } else {
      if (Tab.name === "Corporate IT" || Tab.name === "AppliedKids") {
        return Tab.name;
      } else {
        return ToTitleCase(Tab.name);
      }
    }
  };

  const Tabs = () => {
    return currentTab.map((Tab, Index) => {
      const TitleCaseName = getTitleName(Tab);
      const handleFav = (isFav) => {
        const newState = [...currentTab];
        newState[Index].IsFav = isFav;
        setCurrentTab(newState);
      };

      if ((resources && Tab.IsActive) || !resources) {
        return (
          <li
            key={Index}
            className={`${
              getNavLinkClass(Tab.url)
                ? "md:border-b-0 md:border-l-[5px] md:border-r-0 md:border-t-0 md:border-solid md:border-l-secondary md:bg-base-15 "
                : ""
            } group flex list-none justify-between border-l-0 pl-[15px]  2lg:hover:visible [&:nth-child(1)]:rounded-tr-lg [&_div]:cursor-pointer [&_div]:hover:cursor-pointer [&_i]:hover:cursor-pointer`}
          >
            <NavLink
              className="tab-name"
              onClick={() => {
                handleTabClick(Tab.url);
                if (
                  currentPath.split("/")[1] === "discounts" ||
                  currentPath.split("/")[1] === "resources"
                ) {
                  resetToTop();
                }
              }}
              data-cy={`subnav-link`}
              to={Tab.URL ? Tab.URL : Tab.url}
            >
              {TitleCaseName}
            </NavLink>
            {Tab.name !== "ALL" && Tab.IsFav !== undefined && (
              <div className="flex items-center custom-lg:invisible custom-lg:group-hover:visible">
                <Star
                  index={Index}
                  TeamId={TeamId}
                  page={page}
                  IsFav={Tab.IsFav}
                  TabName={Tab.announcement_type || Tab.SectionID}
                  handleFav={handleFav}
                />
              </div>
            )}
          </li>
        );
      }
    });
  };

  if (resources && !isMobile) {
    return (
      <div
        className="mx-auto my-10 bg-white md:my-0 md:ml-0 md:mr-[15px] md:inline-block md:rounded-lg md:border md:border-solid md:border-base-30 md:shadow-default"
        style={{ width, overflow: "hidden" }}
      >
        <ul className="mt-0 w-full overflow-x-clip  p-0 [&_li:last-child]:border-b-0 [&_li>div]:ml-[10px] [&_li]:border-b [&_li]:border-r-0 [&_li]:border-t-0 [&_li]:border-solid [&_li]:border-b-base-20 [&_li]:py-3 [&_li]:pl-[15px] [&_li]:pr-3 [&_li]:text-[14px] [&_li]:text-secondary [&_li_a]:w-[75%] [&_li_a]:text-inherit [&_li_a]:focus:outline-none [&_p]:border-x-0 [&_p]:border-b [&_p]:border-t-0 [&_p]:border-solid [&_p]:border-base-20 [&_p]:px-[10px] [&_p]:py-3 [&_p]:text-[14px] [&_p]:text-typography-40">
          <Tabs />
        </ul>
      </div>
    );
  }

  return (
    <div
      className="mx-auto my-10 bg-white md:my-0 md:ml-0 md:mr-[15px] md:inline-block md:rounded-lg md:border md:border-solid md:border-base-30 md:shadow-default"
      style={{
        width: isMobileCitySelector ? "100%" : width,
        overflow: "hidden",
      }}
    >
      <Scrollbars
        className={resources ? "custom-lg:relative" : ""}
        style={{ height: isMobile ? height * 1.25 : height, width }}
        autoHide
        universal
      >
        <ul className="mt-0 w-full overflow-x-clip  p-0 [&_li:last-child]:border-b-0 [&_li>div]:ml-[10px] [&_li]:border-b [&_li]:border-r-0 [&_li]:border-t-0 [&_li]:border-solid [&_li]:border-b-base-20 [&_li]:py-3 [&_li]:pl-[15px] [&_li]:pr-3 [&_li]:text-[14px] [&_li]:text-secondary [&_li_a]:w-[75%] [&_li_a]:text-inherit [&_li_a]:focus:outline-none [&_p]:border-x-0 [&_p]:border-b [&_p]:border-t-0 [&_p]:border-solid [&_p]:border-base-20 [&_p]:px-[10px] [&_p]:py-3 [&_p]:text-[14px] [&_p]:text-typography-40">
          <Tabs />
        </ul>
      </Scrollbars>
    </div>
  );
};

SubNav.propTypes = {
  height: propTypes.number,
  width: propTypes.oneOfType([propTypes.string, propTypes.number]),
  page: propTypes.string,
  currentTab: propTypes.array.isRequired,
  setCurrentTab: propTypes.func,
  TeamId: propTypes.string,
  isMobile: propTypes.bool,
  history: propTypes.object,
  closeModal: propTypes.func,
  resources: propTypes.bool,
};

export default SubNav;
