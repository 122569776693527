import React from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import loadable from "@loadable/component";
import AuthorizedRoute from "../AuthorizedRoute/AuthorizedRoute";
import Loading from "../Loading/Loading";
import Login from "../LogInBox/LogInBox";
import {
  ToastProvider,
  DefaultToastContainer,
} from "react-toast-notifications";
import ImageProvider from "../ImageProvider/imageProvider";
import { MOBILE_MAX_WIDTH } from "../AppContainer/AppContainer";

const config = {
  fallback: (
    <div className="flex h-screen w-screen items-center justify-center">
      <Loading centered />
    </div>
  ),
};

const ToastContainer = (props) => (
  <DefaultToastContainer
    className="toast-container"
    style={{ zIndex: 9999 }}
    {...props}
  />
);
const Podcast = loadable(
  () => import(/* webpackPrefetch: true */ "../Podcast/Podcast"),
  config
);

const ChooseToRecycleVideo = loadable(
  () => import(/* webpackPrefetch: true */ "../Videos/Video-ChooseToRecycle"),
  config
);

const LiveVideo = loadable(
  () => import(/* webpackPrefetch: true */ "../Videos/Video-Live"),
  config
);

const LiveUpdate = loadable(
  () => import(/* webpackPrefetch: true */ "../Videos/Video-live-update"),
  config
);

const DirectoryHome = loadable(
  () => import(/* webpackPrefetch: true */ "../DirectoryHome/DirectoryHome"),
  config
);

const SearchBox = loadable(
  () => import(/* webpackPrefetch: true */ "../Home/Home"),
  config
);
const Portals = loadable(
  () => import(/* webpackPrefetch: true */ "../Portals/Portals"),
  config
);
const Directory = loadable(
  () => import(/* webpackPrefetch: true */ "../DirectoryPage/DirectoryPage"),
  config
);

const Resources = loadable(
  () => import(/* webpackPrefetch: true */ "../Resources/Resources"),
  config
);
const Search = loadable(
  () => import(/* webpackPrefetch: true */ "../Search/Search"),
  config
);
const Grid = loadable(
  () => import(/* webpackPrefetch: true */ "../Grid/Grid.jsx"),
  config
);
const Discounts = loadable(() =>
  import(/* webpackPrefetch: true */ "../Discounts/Discounts.jsx")
);
const Events = loadable(
  () => import(/* webpackPrefetch: true */ "../Events/Events"),
  config
);
const ErrorPage = loadable(
  () => import(/* webpackPrefetch: true */ "../Error/Error"),
  config
);
const Announcements = loadable(
  () => import(/* webpackPrefetch: true */ "../Announcement/Announcement"),
  config
);
const ATSNewsLetter = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "../ATSNewsLetter/EditionOne/ATSNewsLetter"
    ),
  config
);

const ATSNewsLetterEditionThree = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "../ATSNewsLetter/EditionThree/ATSNewsLetterEditionThree"
    ),
  config
);

const ATSNewsLetterEditionTwo = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "../ATSNewsLetter/EditionTwo/ATSNewsLetterEditionTwo"
    ),
  config
);

const ATSAMENewsLetterEditionOne = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "../ATSAMENewsLetter/EditionOne/ATSNewsLetterEditionOne"
    ),
  config
);

const ATSAMENewsLetterEditionTwo = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ "../ATSAMENewsLetter/EditionTwo/ATSNewsLetterEditionTwo"
    ),
  config
);

const CompanyOrgChart = loadable(
  () => import(/* webpackPrefetch: true */ "../OrgChart/OrganizationChart"),
  config
);
const CampusMaps = loadable(
  () => import(/* webpackPrefetch: true */ "../CampusMaps/CampusMaps"),
  config
);

const UserProfile = loadable(
  () => import(/* webpackPrefetch: true */ "../UserProfile/UserProfile"),
  config
);

const ECommerceApp = loadable(
  () => import(/* webpackPrefetch: true */ "../AppLauncher/eCommerce/App.jsx"),
  config
);

const Fivezeroonek = loadable(
  () => import(/* webpackPrefetch: true*/ "../Videos/Video-501k"),
  config
);

const Facemask = loadable(
  () => import(/* webpackPrefetch: true*/ "../Videos/Face-Mask"),
  config
);

const TrekToSanta = loadable(
  () => import(/* webpackPrefetch: true*/ "../Videos/TrekToSanta"),
  config
);

const HolidayVideo2020 = loadable(
  () => import(/* webpackPrefetch: true*/ "../Videos/HolidayVideo2020"),
  config
);

const LoginTraining = loadable(
  () => import(/* webpackPrefetch: true*/ "../Videos/LoginTraining"),
  config
);

const MessageFromSaid = loadable(
  () => import(/* webpackPrefetch: true*/ "../Videos/MessageFromSaid"),
  config
);

const ThreeDPrinting = loadable(
  () => import(/* webpackPrefetch: true*/ "../Videos/Video-3dprinting"),
  config
);

const TrainingTree = loadable(() =>
  import(/* webpackPrefetch: true*/ "../TrainingTree")
);

const StoreReceipt = loadable(() =>
  import(
    /* webpackPrefetch: true*/ "../AppLauncher/eCommerce/StoreReceipt/StoreReceipt"
  )
);

const AppRouter = () => {
  // These variables are required as props for the ImageProvider component--cannot use useViewPort hook here--will cause rerenders every time browser window is resized
  const width = Math.min(document.documentElement.clientWidth, innerWidth || 0);
  const height = Math.max(
    document.documentElement.clientHeight,
    innerHeight || 0
  );
  const isMobile = width < MOBILE_MAX_WIDTH;
  const setCalculateViewport = () => {};

  return (
    <ToastProvider
      autoDismissTimeout={10000}
      placement="bottom-right"
      components={{ ToastContainer }}
    >
      <BrowserRouter>
        <ImageProvider {...{ width, height, isMobile, setCalculateViewport }}>
          <Switch>
            <AuthorizedRoute
              path="/"
              exact
              render={(props) => <SearchBox {...props} />}
            />
            <AuthorizedRoute
              path="/notifications"
              render={(props) => <SearchBox {...props} />}
            />
            <AuthorizedRoute
              path="/search"
              exact
              render={(props) => <Search {...props} />}
            />
            <AuthorizedRoute
              path="/search/:searchTerm"
              exact
              render={(props) => <Search {...props} />}
            />
            <AuthorizedRoute
              path="/dashboard/:id"
              render={(props) => <Grid {...props} />}
            />
            <AuthorizedRoute
              path="/orgchart/:teamid"
              exact
              render={(props) => <CompanyOrgChart {...props} />}
            />
            <AuthorizedRoute
              path="/directory"
              exact
              render={(props) => <DirectoryHome {...props} />}
            />
            <AuthorizedRoute
              path="/directory/:searchTerm/:id"
              exact
              activeStyle={{ color: "red" }}
              render={(props) => <Directory {...props} />}
            />
            <AuthorizedRoute
              path="/directory/:searchTerm/:id/dr"
              exact
              render={(props) => <Directory {...props} />}
            />
            <AuthorizedRoute
              path="/discounts/:category"
              exact
              render={(props) => <Discounts {...props} />}
            />
            <AuthorizedRoute
              path="/discounts/search/:search"
              exact
              render={(props) => <Discounts {...props} />}
            />
            <AuthorizedRoute
              path="/events"
              render={(props) => <Events {...props} />}
            />
            <AuthorizedRoute
              path="/announcements"
              exact
              render={(props) => <Announcements {...props} />}
            />
            <AuthorizedRoute
              path="/announcements/:id"
              exact
              render={(props) => <Announcements {...props} />}
            />
            <AuthorizedRoute
              path="/announcements/search/:search"
              exact
              render={(props) => <Announcements {...props} />}
            />
            <AuthorizedRoute
              path="/announcements/category/:category"
              exact
              render={(props) => <Announcements {...props} />}
            />
            <AuthorizedRoute
              path="/portals"
              exact
              render={(props) => <Portals {...props} />}
            />
            <AuthorizedRoute
              path="/portals/:category"
              exact
              render={(props) => <Portals {...props} />}
            />
            <AuthorizedRoute
              path="/portals/search/:search"
              render={(props) => <Portals {...props} />}
            />
            <AuthorizedRoute
              path="/resources"
              exact
              render={(props) => <Resources {...props} />}
            />
            <AuthorizedRoute
              path="/resources/:category"
              exact
              render={(props) => <Resources {...props} />}
            />
            <AuthorizedRoute
              path="/resources/search/:search"
              exact
              render={(props) => <Resources {...props} />}
            />
            <AuthorizedRoute
              path="/resources/reports/:ReportID"
              exact
              render={(props) => <Resources {...props} />}
            />
            <AuthorizedRoute
              path="/resources/*/:edgecase"
              exact
              render={(props) => <Resources {...props} />}
            />
            <AuthorizedRoute
              path="/campusmap/:city"
              exact
              render={(props) => <CampusMaps {...props} />}
            />
            <AuthorizedRoute
              path="/campusmap/:city/buildings"
              render={(props) => <CampusMaps {...props} />}
            />
            <AuthorizedRoute
              path="/campusmap/:city/fullmap"
              render={(props) => <CampusMaps {...props} />}
            />
            <AuthorizedRoute
              path="/profile"
              exact
              render={(props) => <UserProfile {...props} />}
            />
            <AuthorizedRoute
              path="/applied-shop/receipt/:paymentId"
              exact
              render={(props) => <StoreReceipt {...props} />}
            />
            <AuthorizedRoute
              path="/applied-shop"
              render={(props) => <ECommerceApp {...props} />}
            />
            <AuthorizedRoute
              path="/ChooseToRecycle"
              exact
              render={(props) => <ChooseToRecycleVideo {...props} />}
            />
            <AuthorizedRoute
              path="/live"
              exact
              render={(props) => <LiveVideo {...props} />}
            />
            <AuthorizedRoute
              path="/live-update"
              exact
              render={(props) => <LiveUpdate {...props} />}
            />
            <AuthorizedRoute
              path="/510k"
              exact
              render={(props) => <Fivezeroonek {...props} />}
            />
            <AuthorizedRoute
              path="/oneteamoneheart"
              exact
              render={(props) => <Facemask {...props} />}
            />
            <AuthorizedRoute
              path="/3dprinting"
              exact
              render={(props) => <ThreeDPrinting {...props} />}
            />
            <AuthorizedRoute
              path="/training-tree/:moduleId"
              // exact
              render={(props) => <TrainingTree {...props} />}
            />
            <AuthorizedRoute
              path="/atsnewsletter/editionOne"
              exact
              render={(props) => <ATSNewsLetter {...props} />}
            />
            <AuthorizedRoute
              path="/atsnewsletter/EditionThree"
              exact
              render={(props) => <ATSNewsLetterEditionThree {...props} />}
            />
            <AuthorizedRoute
              path="/atsnewsletter/editionTwo"
              exact
              render={(props) => <ATSNewsLetterEditionTwo {...props} />}
            />
            <AuthorizedRoute
              path="/atsamenewsletter/editionOne"
              exact
              render={(props) => <ATSAMENewsLetterEditionOne {...props} />}
            />
            <AuthorizedRoute
              path="/atsamenewsletter/editionTwo"
              exact
              render={(props) => <ATSAMENewsLetterEditionTwo {...props} />}
            />
            <AuthorizedRoute
              path="/podcast"
              exact
              render={(props) => <Podcast {...props} />}
            />
            <AuthorizedRoute
              path="/Newsroom"
              exact
              render={(props) => <SearchBox {...props} />}
            />
            <Route
              path="/appliedoneteamoneheart"
              exact
              render={(props) => <Facemask {...props} />}
            />
            <Route
              path="/logintraining"
              exact
              render={(props) => <LoginTraining {...props} />}
            />
            <Route
              path="/trektosanta"
              exact
              render={(props) => <TrekToSanta {...props} />}
            />
            <Route
              path="/holidayvideo2020"
              exact
              render={(props) => <HolidayVideo2020 {...props} />}
            />
            <Route
              path="/MessageFromSaid"
              exact
              render={(props) => <MessageFromSaid {...props} />}
            />
            <Route path="/login" exact component={Login} />
            <Route path="/jwtlogin" exact render={() => <Login jwt />} />
            <AuthorizedRoute render={(props) => <ErrorPage {...props} />} />
          </Switch>
        </ImageProvider>
      </BrowserRouter>
    </ToastProvider>
  );
};

export default AppRouter;
