import React, { useState } from "react";
import SubNav from "../SubNav/SubNav";
import Modal from "react-responsive-modal";
import { exitButton } from "_shared/modal";
// import { findIndex, propEq } from "ramda";
import propTypes from "prop-types";

const MobileCitySelector = ({
  currentTab,
  isMobile,
  isMedScreen,
  title,
  width,
  history,
  height,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOnClick = () => {
    setOpenModal(!openModal);
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const styles = {
    modal: {
      width: "100dvw",
      height: "100dvh",
      borderRadius: "0.5rem",
      // boxShadow: "0 0 20px rgba(241, 241, 242, 0.7)",
      padding: 0,
      //background: none,
      background: "#ffffff",
      overflow: "hidden",
    },
  };

  return (
    <div
      className={`flex w-auto cursor-pointer justify-between border border-solid border-[#fafbfb] bg-white md:w-[200px] lg:w-[300px] rounded-[4px] ${isMedScreen ? "h-fit" : "h-[34px]"}`}
      onClick={handleOnClick}
    >
        <div 
          className={` ${isMedScreen ? "h-11" : ""} flex items-center ml-4 text-sm font-normal uppercase text-[#9da3a9]`}
          data-testid="mobile-city-selector-container"
        >
          {title}
        </div>
        <i 
          className="fal fa-chevron-down fa-lg ml-2 mr-4 flex cursor-pointer items-center text-[#e3e5e6]"
          onClick={handleOnClick}
        />
      <Modal
        styles={styles}
        open={openModal}
        onClose={closeModal}
        closeIconSvgPath={exitButton}
      >
        <div
        className="pt-[30px]"
        >
        <SubNav
          height={height}
          width={width}
          isMobile={true}
          isMobileCitySelector={true}
          closeModal={closeModal}
          history={history}
          //   page={page}
          currentTab={currentTab}
          //   setCurrentTab={setCurrentTab}
          //   TeamId={TeamId}
          //   resources={resources}
        />
        </div>
      </Modal>
    </div>

    // </button>
  );
};

MobileCitySelector.propTypes = {
  currentTab: propTypes.array,
  setCurrentTab: propTypes.func,
  title: propTypes.string,
  width: propTypes.number,
  history: propTypes.object,
  height: propTypes.number,
};

export default MobileCitySelector;
