import React from "react";
import propTypes from "prop-types";
import logo from "_shared/images/delta.png";
import { Link } from "react-router-dom";
import globe from "_shared/images/global.svg";
import WorldClock from "../WorldClock/WorldClock";
import Navlinks from "../Navlinks/Navlinks";

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const Sidebar = ({
  collapsed,
  setCollapsed,
  showWorldClocks,
  setShowWorldClocks,
  user,
  isMobile,
}) => {
  return (
    <div
      id="sidenavbar"
      className="text-white"
      style={{ width: collapsed ? 70 : 250 }}
    >
      <div
        data-cy="sidebar"
        onClick={() => {
          setCollapsed(!collapsed);
        }}
        className="z-10 h-screen bg-black"
        style={{
          cursor: "pointer",
          width: collapsed ? 70 : 250,
          opacity: collapsed ? 0.3 : 0.6,
        }}
      />
      {!collapsed && (
        <Link
          to="/"
          className="float:left absolute left-1 top-3 px-5 py-4 leading-5"
        >
          <img
            className="h-[30px] w-auto animate-fade-in"
            alt="screen background"
            onClick={() => {
              setCollapsed(true);
            }}
            src={logo.src}
            srcSet={logo.srcSet}
          />
        </Link>
      )}
      {!collapsed && (
        <div>
          <div className="absolute bottom-4 left-[50px] w-[200px] animate-fade-in">
            <WorldClock collapsed={false} sidebar={true} timeZone={timeZone} />
            <img
              data-cy="globe"
              src={globe}
              className="absolute bottom-3 left-28 h-6 w-6 cursor-pointer"
              alt="globe"
              onClick={() => setShowWorldClocks(!showWorldClocks)}
            />
          </div>
        </div>
      )}
      <div
        className="absolute left-[210px] top-[28px] z-20 rounded-l-[4px] px-0 pb-0 pt-2 opacity-90"
        style={{ cursor: "pointer", display: collapsed ? "none" : "" }}
      >
        <i
          className="fas fa-chevron-left animate-fade-in"
          onClick={() => {
            setCollapsed(true);
          }}
        ></i>
      </div>
      <div
        className="animate-fade-in"
        style={{ display: collapsed ? "" : "none" }}
      >
        <div>
          <Link to="/" data-cy="sidebar-home-logo">
            <img
              className="absolute left-[17.5px] top-8 h-[30px] w-auto"
              alt="delta"
              tabIndex={0}
              src={logo.src}
              srcSet={logo.srcSet}
            />
          </Link>
        </div>
        <div>
          <i
            className="fas fa-chevron-right absolute left-[30px] top-[85px] cursor-pointer"
            onClick={() => {
              setCollapsed(false);
            }}
          ></i>
        </div>
        <img
          data-cy="globe"
          src={globe}
          className="absolute bottom-[3.4rem] left-5 h-[2.5rem] w-[2.5rem] cursor-pointer text-white"
          alt="globe"
          onClick={() => setShowWorldClocks(!showWorldClocks)}
        />
        <div className=" absolute bottom-[86px] left-0 block w-[70px] text-center">
          <WorldClock collapsed={true} timeZone={timeZone} />
        </div>
      </div>
      <div className="absolute left-[90px] top-0 z-10">
        <ul
          className="absolute left-[-90px] top-[70px] w-screen max-w-[250px] animate-fade-in list-none p-0 text-[14px] font-normal uppercase visited:text-white [&_a:hover]:text-white [&_a]:block [&_a]:py-2 [&_a]:pl-6 [&_a]:pr-0 [&_a]:text-gray-shades-80 [&_li]:w-full" // left off here, fixed navLinks
          style={{ display: collapsed ? "none" : "" }}
        >
          <Navlinks setCollapsed={setCollapsed} user={user} />
        </ul>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  collapsed: propTypes.bool.isRequired,
  setCollapsed: propTypes.func.isRequired,
};

export default Sidebar;
