import React, { useState, useEffect } from "react";
import { pathOr, includes } from "ramda";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Navlinks = ({ setCollapsed, user }) => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <li>
        <NavLink
          data-cy="navlink-root"
          style={{ textDecoration: "none" }}
          exact
          activeClassName="relative  bg-base-80 [&_span]:text-white [&_a]:visited:text-white border-l-[5px] border-secondary [border-left-style:solid]  [&_span]:left-[-5px]"
          onClick={() => {
            setCollapsed(true);
            document.title = "myApplied - Home";
          }}
          to="/"
        >
          <span className="relative">{t("Home")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          data-cy="navlink-dashboard"
          style={{ textDecoration: "none" }}
          activeClassName="relative  bg-base-80 [&_span]:text-white [&_a]:visited:text-white border-l-[5px] border-secondary [border-left-style:solid]  [&_span]:left-[-5px]"
          onClick={() => {
            setCollapsed(true);
            document.title = "myApplied - Dashboard";
          }}
          to="/dashboard/home"
        >
          <span className="relative">{t("Dashboard")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          data-cy="navlink-announcements"
          style={{ textDecoration: "none" }}
          activeClassName="relative  bg-base-80 [&_span]:text-white [&_a]:visited:text-white border-l-[5px] border-secondary [border-left-style:solid]  [&_span]:left-[-5px]"
          onClick={() => {
            document.title = "myApplied - Announcements";
            setCollapsed(true);
          }}
          to="/announcements"
        >
          <span className="relative">{t("Announcements")}</span>
        </NavLink>
      </li>
      {user?.isUS && (
        <li>
          <NavLink
            data-cy="navlink-apps"
            style={{ textDecoration: "none" }}
            activeClassName="relative  bg-base-80 [&_span]:text-white [&_a]:visited:text-white border-l-[5px] border-secondary [border-left-style:solid]  [&_span]:left-[-5px]"
            onClick={() => {
              document.title = "myApplied - eCommerce";
              setCollapsed(true);
            }}
            isActive={(_, { pathname }) => {
              return includes("/applied-shop/", pathname);
            }}
            to="/applied-shop/home"
          >
            <span className="relative">{t("Store")}</span>
          </NavLink>
        </li>
      )}
      <li>
        <NavLink
          data-cy="navlink-campusmap"
          style={{ textDecoration: "none" }}
          activeClassName="relative  bg-base-80 [&_span]:text-white [&_a]:visited:text-white border-l-[5px] border-secondary [border-left-style:solid]  [&_span]:left-[-5px]"
          onClick={() => {
            document.title = "myApplied - Campus Maps";
            setCollapsed(true);
          }}
          isActive={(_, { pathname }) => {
            return includes("/campusmap/", pathname);
          }}
          to="/campusmap/rsm"
        >
          <span className="relative">{t("Campus Map")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          data-cy="navlink-directory"
          style={{ textDecoration: "none" }}
          activeClassName="relative  bg-base-80 [&_span]:text-white [&_a]:visited:text-white border-l-[5px] border-secondary [border-left-style:solid]  [&_span]:left-[-5px]"
          onClick={() => {
            document.title = "myApplied - Directory";
            setCollapsed(true);
          }}
          to="/directory"
        >
          <span className="relative">{t("Directory")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          data-cy="navlink-discounts"
          style={{ textDecoration: "none" }}
          activeClassName="relative  bg-base-80 [&_span]:text-white [&_a]:visited:text-white border-l-[5px] border-secondary [border-left-style:solid]  [&_span]:left-[-5px]"
          onClick={() => {
            document.title = "myApplied - Discounts";
            setCollapsed(true);
          }}
          isActive={(_, { pathname }) => {
            return includes("/discounts/", pathname);
          }}
          to="/discounts/restaurant"
        >
          <span className="relative">{t("Discounts")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          data-cy="navlink-events"
          style={{ textDecoration: "none" }}
          activeClassName="relative  bg-base-80 [&_span]:text-white [&_a]:visited:text-white border-l-[5px] border-secondary [border-left-style:solid]  [&_span]:left-[-5px]"
          onClick={() => {
            document.title = "myApplied - Calendar";
            setCollapsed(true);
          }}
          to="/events"
        >
          <span className="relative">{t("Events")}</span>
        </NavLink>
      </li>
      {!pathOr(true, ["isSales"], user) && (
        <li>
          <NavLink
            data-cy="navlink-orgchart"
            style={{ textDecoration: "none" }}
            activeClassName="relative  bg-base-80 [&_span]:text-white [&_a]:visited:text-white border-l-[5px] border-secondary [border-left-style:solid]  [&_span]:left-[-5px]"
            onClick={() => {
              document.title = "myApplied - Organizational Chart";
              setCollapsed(true);
            }}
            to={`/orgchart/${user.TeamId}`}
          >
            <span className="relative">{t("Org Chart")}</span>
          </NavLink>
        </li>
      )}
      <li>
        <NavLink
          data-cy="navlink-portals"
          style={{ textDecoration: "none" }}
          activeClassName="relative  bg-base-80 [&_span]:text-white [&_a]:visited:text-white border-l-[5px] border-secondary [border-left-style:solid]  [&_span]:left-[-5px]"
          onClick={() => {
            document.title = "myApplied - Portals";
            setCollapsed(true);
          }}
          to="/portals/"
        >
          <span className="relative">{t("Portals")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          data-cy="navlink-resources"
          style={{ textDecoration: "none" }}
          activeClassName="relative  bg-base-80 [&_span]:text-white [&_a]:visited:text-white border-l-[5px] border-secondary [border-left-style:solid]  [&_span]:left-[-5px]"
          onClick={() => {
            document.title = "myApplied - Resources";
            setCollapsed(true);
          }}
          isActive={(_, { pathname }) => {
            return includes("/resources/", pathname);
          }}
          to="/resources/Payroll-&-Benefits"
        >
          <span className="relative">{t("Resources")}</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          data-cy="training-tree"
          style={{ textDecoration: "none" }}
          activeClassName="relative  bg-base-80 [&_span]:text-white [&_a]:visited:text-white border-l-[5px] border-secondary [border-left-style:solid]  [&_span]:left-[-5px]"
          onClick={() => {
            document.title = "myApplied - Training Tree";
            setCollapsed(true);
          }}
          isActive={(_, { pathname }) => {
            return includes("/training-tree/", pathname);
          }}
          to="/training-tree/1"
        >
          <span className="relative">{t("Training Tree")}</span>
        </NavLink>
      </li>
    </>
  );
};

export default Navlinks;
