import React, { useEffect, useState, useRef } from "react";
import { useLocation} from "react-router-dom";
import propTypes from "prop-types";
import Header from "../Header/Header";
import ClockOverlay from "../ClockOverlay/Clocks";
import Sidebar from "../Sidebar/Sidebar";
import MobileNavBar from "../MobileNavBar/MobileNavBar";
import MobileNavBarMaps from "../MobileNavBar/MobileNavBarMaps";
import SystemOutageBanner from "../SystemOutage/SystemOutageBanner";
import useViewport from "_shared/useViewport";
import { pathOr } from "ramda";
import { isAuthenticated } from "_shared/auth";
import MobileHeader from "../MobileHeader/MobileHeader";
import { user } from "_shared/auth";
import "./AppContainer.scss";
//graphql
import { graphql } from "_shared/request";
import * as R from "ramda";

import { useSystemOutage } from "../SystemOutage/context/SystemOutageContext";
// BOTH
export const PADDING = 25;

// WIDTH
export const COLLAPSED_WIDTH = 70;
export const EXPANDED_WIDTH = 250;
export const MOBILE_MAX_WIDTH = 769;

// HEIGHT
export const SEARCH_BAR_HEIGHT = 34;
export const MOBILE_HEADER_HEIGHT = 75;
export const MOBILE_TOP_NAV_HEIGHT = 70;

const hasBackground = (sidebarCollapsed, isMobile) => {
  if (isMobile && !sidebarCollapsed) {
    return "[text-shadow:0px_0px_8px_rgba(0,0,0,0.3)";
  } else {
    return "bg-[#fafbfb]";
  }
};

const AppContainer = ({
  containerRef,
  bgColor,
  title,
  placeholder,
  render,
  renderHeader,
  onSearch,
  handleSearch,
  handleSearchSubmit,
  handleInvalid,
  searchString,
  history,
  noSearch = false, 
  scrollbar = false,
  withBackground = true,
  dashboard = false,
  isMaps = false,
  isDirectory = false,
  errorMessage,
  currentTab,
  city,
  setBuildingName,
  Ecommerce,
  onTitleClick,
  style,
  ...rest
}) => {
  let location = useLocation();
  const [isOverflowing, setIsOverflowing] = useState(false);
  const paraRef = useRef(null);
  const bannerRef = useRef(null);

  const {
    displayBanner,
    setBannerHeight,
    isNewsroomOpen,
    pagesToDisplayBanner,
  } = useSystemOutage();

  useEffect(() => {
    const { current } = paraRef || {};
    if (
      current?.scrollHeight &&
      current?.offsetHeight &&
      current.scrollHeight > current.offsetHeight
    ) {
      setIsOverflowing(true);
    }
  }, []);

  useEffect(() => {
    const bannerElement = bannerRef.current;
    if (!bannerElement) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setBannerHeight(entry.borderBoxSize[0].blockSize);
      }
    });

    resizeObserver.observe(bannerElement);

    // Clean up function
    return () => {
      resizeObserver.unobserve(bannerElement);
    };
  }, []);

  const [collapsed, setCollapsed] = useState(true);
  const [showWorldClocks, setShowWorldClocks] = useState(false);

  const { width, height, isMobile, setCalculateViewport } = useViewport();
  const isIphoneSize = width < 415;
  const sidebarAndMobileAdjustedWidth =
    width - (isMobile ? 0 : collapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH);

  const container = useRef(null);

  useEffect(() => {

    //MappedIn uses hash fragments which causes the hash to attach to the route navigated after maps so need to remove it

      if (!window.location.pathname.includes("/campusmap") && (window.location.hash.includes("#/") || window.location.href.includes("#/"))) {

        window.history.replaceState({}, "", window.location.pathname + window.location.search)
      }

  }, [window.location.hash, window.location.href])

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  // necessary for resource page to reset scroll position
  useEffect(() => {
    if (containerRef) {
      containerRef(container);
    }
  }, []);

  useEffect(() => {
    if (pathOr(false, ["Appcues", "page"], window)) {
      window.Appcues.page();
    }
  }, []);

  // REMOVED GRAPHQL FUNCTION FOR ANALYTICS DATA, TO CHECK WHO VISITED MYAPPLIED HOME PAGE

  useEffect(() => {
    const columns_to_update = [];
    const currentTime = new Date();
    const user_data = {
      ...user,
      updated_at: currentTime,
    };

    //get rid of null values
    for (const [key, value] of Object.entries(user_data)) {
      if (R.isNil(value)) {
        user_data[key] = "n/a";
      }
    }

    for (const [key, value] of Object.entries(user)) {
      if (value !== null && value !== "") {
        columns_to_update.push(key);
      }
    }

    columns_to_update.push("updated_at");

    Object.freeze(columns_to_update);

    async function updateUserData() {
      const payload = {
        ...user_data,
        update_columns: columns_to_update || [],
      };

      const operationsDoc = `
        mutation MyMutation($AppRoles: String = "n/a", $City: String = "n/a", $CompanyName: String = "n/a", $CostCenter: String = "n/a", $CountryCode: String = "n/a", $EmpStatus: String = "n/a", $IsManager: Boolean = false, $IsManualUser: Boolean = false, $IsManualUserManagerLMS2: Boolean = false, $Shift: String = "n/a", $TeamId: String = "n/a", $TeamMember: String = "n/a", $email: String = "n/a", $firstName: String = "n/a", $isAdmin: Boolean = false, $isAustralia: Boolean = false, $isAuthenticated: Boolean = false, $isClinicalDev: Boolean = false, $isEurope: Boolean = false, $isIrvine: Boolean = false, $isLakeForest: Boolean = false, $isRSM: Boolean = false, $isSales: Boolean = false, $isSupervisor: Boolean = false, $isUS: Boolean = false, $isUSCompanyUpdate: Boolean = false, $lastName: String = "n/a", $location: String = "n/a", $status: Int = 0, $subArea: String = "n/a", $UserType: String = "n/a", $username: String = "n/a", $updated_at: timestamptz, $update_columns: [user_update_column!] = AppRoles, $State: String = "", $SubAreaTxt: String = "", $TimeZone: String = "", $CompanyCode: String = "") {
          insert_user(objects: {AppRoles: $AppRoles, City: $City, CompanyName: $CompanyName, CostCenter: $CostCenter, CountryCode: $CountryCode, EmpStatus: $EmpStatus, IsManager: $IsManager, IsManualUser: $IsManualUser, IsManualUserManagerLMS2: $IsManualUserManagerLMS2, Shift: $Shift, TeamId: $TeamId, TeamMember: $TeamMember, email: $email, firstName: $firstName, isAdmin: $isAdmin, isAustralia: $isAustralia, isAuthenticated: $isAuthenticated, isClinicalDev: $isClinicalDev, isEurope: $isEurope, isIrvine: $isIrvine, isLakeForest: $isLakeForest, isRSM: $isRSM, isSales: $isSales, isSupervisor: $isSupervisor, isUS: $isUS, isUSCompanyUpdate: $isUSCompanyUpdate, lastName: $lastName, location: $location, status: $status, subArea: $subArea, UserType: $UserType, username: $username, updated_at: $updated_at, State: $State, SubAreaTxt: $SubAreaTxt, TimeZone: $TimeZone, CompanyCode: $CompanyCode}, on_conflict: {constraint: user_myapplied_pkey, update_columns: $update_columns}) {
            affected_rows
            returning {
              AppRoles
              City
              CompanyName
              CostCenter
              CountryCode
              EmpStatus
              IsManager
              IsManualUser
              IsManualUserManagerLMS2
              Shift
              TeamId
              TeamMember
              created_at
              email
              firstName
              isAdmin
              isAustralia
              isAuthenticated
              isClinicalDev
              isEurope
              isIrvine
              isLakeForest
              isRSM
              isSales
              isSupervisor
              isUS
              isUSCompanyUpdate
              lastName
              location
              status
              subArea
              updated_at
              username
              CompanyCode
              SubAreaTxt
              TimeZone
              State
            }
          }
        }
      `;
      try {
        const response = await graphql(operationsDoc, payload).then(
          (response) => {
            console.log("user table response: ", response);
          }
        );
      } catch (error) {
        console.log("user table error: ", error);
      }
    }
    if (Object.keys(user).length !== 0) updateUserData();
  }, []);

  const showBanner = () => {
    const pageTest = (el) => {
      console.log({ pathname: location.pathname, el });
      return location.pathname.includes(el);
    };
    const checkIfPageIncludes =
      pagesToDisplayBanner.findIndex(pageTest) > -1 ? true : false;
    console.log({ checkIfPageIncludes });
    if (isMobile) {
      return collapsed && displayBanner && checkIfPageIncludes;
    }
    return displayBanner && checkIfPageIncludes;
  };

  return (
    <>
      {showWorldClocks && <ClockOverlay collapsed={collapsed} />}
      {isAuthenticated && (
        <div>
          {!isMobile ? (
            <Sidebar
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              isMobile={isMobile}
              user={user ? user : { TeamId: "15116" }}
              showWorldClocks={showWorldClocks}
              setShowWorldClocks={(...props) => {
                setShowWorldClocks(...props);
              }}
            />
          ) : (
            isMaps ?  
            (
            <MobileNavBarMaps
              setBuildingName={setBuildingName}
              width={sidebarAndMobileAdjustedWidth - PADDING * 2}
              height={ height - (MOBILE_TOP_NAV_HEIGHT + MOBILE_HEADER_HEIGHT + PADDING * 2) - 55}
              city={city}
              collapsed={collapsed}
              noSearch={noSearch}
              setCollapsed={setCollapsed}
              showWorldClocks={showWorldClocks}
              setShowWorldClocks={(...props) => {
                setShowWorldClocks(...props);
              }}
              currentTab={currentTab}
              handleSearch={handleSearch}
              handleSearchSubmit={handleSearchSubmit}
              withBackground={withBackground}
              searchPlaceHolder={placeholder || title}
              user={user}
              history={history}
              isMobile={isMobile}
            />
          ) : (
            <MobileNavBar
              collapsed={collapsed}
              noSearch={noSearch}
              setCollapsed={setCollapsed}
              showWorldClocks={showWorldClocks}
              setShowWorldClocks={(...props) => {
                setShowWorldClocks(...props);
              }}
              handleSearch={handleSearch}
              handleSearchSubmit={handleSearchSubmit}
              withBackground={withBackground}
              searchPlaceHolder={placeholder || title}
              user={user}
              history={history}
              isMobile={isMobile}
            />
            )
          )}
        </div>
      )}
      <div
        // styleName={`container ${
        //   withBackground ? hasBackground(collapsed, isMobile) : "textShadow"
        // }`}
        className={`absolute overflow-auto md:h-screen md:p-0 ${
          withBackground
            ? hasBackground(collapsed, isMobile)
            : "[text-shadow:0px_0px_8px_rgba(0,0,0,0.3)]"
        }`}
        ref={container}
        style={{
          backgroundColor: bgColor && !isMobile ? bgColor : "none",
          left: isMobile ? 0 : collapsed ? COLLAPSED_WIDTH : EXPANDED_WIDTH,
          top: isMobile ? MOBILE_TOP_NAV_HEIGHT : 0,
          width: sidebarAndMobileAdjustedWidth,
          height: height - (isMobile ? MOBILE_TOP_NAV_HEIGHT : 0),
          overflow:
            Ecommerce || (displayBanner && isNewsroomOpen) ? "hidden" : "",
          ...style,
        }}
      >
        {showBanner() && (
          <SystemOutageBanner
            isOverflowing={isOverflowing}
            paraRef={paraRef}
            bannerRef={bannerRef}
          />
        )}
        <div
          style={{
            paddingLeft: isMobile && isMaps ? 0 : PADDING,
            paddingRight: isMobile && isMaps ? 0 : PADDING,
          }}
        >
          <div
            style={{
              display: !collapsed && isMobile ? "none" : "",
            }}
          >
            {renderHeader ? (
              renderHeader({
                title,
                container,
                onSearch,
                noSearch,
                withBackground,
                handleSearch,
                handleSearchSubmit,
                placeholder,
                dashboard,
                onTitleClick,
                isMobile,
                history,
              })
            ) : (
              <DefaultHeader
                title={title}
                container={container}
                onSearch={onSearch}
                noSearch={noSearch}
                withBackground={withBackground}
                handleSearch={handleSearch}
                handleSearchSubmit={handleSearchSubmit}
                handleInvalid={handleInvalid}
                placeholder={placeholder}
                dashboard={dashboard}
                onTitleClick={onTitleClick}
                isMobile={isMobile}
                history={history}
                Ecommerce={Ecommerce}
                isDirectory={isDirectory}
                errorMessage={errorMessage}
                newsroomWidth={sidebarAndMobileAdjustedWidth}
              />
            )}
            {render({
              width: sidebarAndMobileAdjustedWidth - PADDING * 2,
              height:
                height -
                (isMobile
                  ? MOBILE_TOP_NAV_HEIGHT + MOBILE_HEADER_HEIGHT + PADDING * 2
                  : SEARCH_BAR_HEIGHT + PADDING * 2) -
                (isMobile ? 55 : 54), //ternary statement for last subtrahend in calculation is for adjustments to mobile applied newsroom message
              isMobile,
              setCalculateViewport,
              isIphoneSize,
              history,
              ...rest,
            })}
          </div>
        </div>
      </div>
    </>
  );
};

const DefaultHeader = ({
  title,
  container,
  onSearch,
  noSearch,
  withBackground,
  handleSearch,
  handleSearchSubmit,
  handleInvalid,
  placeholder,
  dashboard,
  onTitleClick,
  isMobile,
  history,
  Ecommerce,
  isDirectory,
  errorMessage,
  newsroomWidth,
}) => {
  if (isMobile)
    return (
      <MobileHeader
        container={container}
        title={title}
        withBackground={withBackground}
        history={history}
        onTitleClick={onTitleClick}
        isMobile={isMobile}
      />
    );

  return (
    <Header
      title={title}
      onSearch={onSearch}
      noSearch={noSearch}
      // styleName={withBackground ? "BGcontainer" : "noBGcontainer"}
      className={withBackground ? "top-4" : "top-[30px]"}
      handleSearch={handleSearch}
      handleSearchSubmit={handleSearchSubmit}
      handleInvalid={handleInvalid}
      withBackground={withBackground}
      searchPlaceHolder={placeholder || title}
      history={history}
      user={user}
      dashboard={dashboard}
      container={container}
      onTitleClick={onTitleClick}
      isMobile={isMobile}
      Ecommerce={Ecommerce}
      isDirectory={isDirectory}
      errorMessage={errorMessage}
      newsroomWidth={newsroomWidth}
    />
  );
};

AppContainer.propTypes = {
  render: propTypes.func.isRequired,
};

export default AppContainer;
